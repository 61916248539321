@tailwind base;
@tailwind components;
@tailwind utilities;


/* Tailwind automatically applies this when dark mode is enabled on the body */
body.dark {
  background-color: #1a202c; /* Dark background */
  color: white; /* Light text color */
}

body.dark .bg-white {
  background-color: #2d3748; /* Dark background for white elements */
}

body.dark .text-gray-500 {
  color: #a0aec0; /* Dark gray text */
}
